<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="费用单" width="700px" @closed="handleClosed">
    <template v-if="form">
      <el-form ref="form" :model="form" label-width="66px" label-position="right" label-suffix=":">
        <div class="h s sb">
          <div style="width: 50%; margin-right: 30px;">
            <form-info-item label="供应商">{{form.entName}}</form-info-item>
            <form-info-item label="记账类型">{{form.projectName}}</form-info-item>
            <form-info-item label="支付金额" inline>
              <b class="fs-large">{{$price(form.payMoney)}}</b>
            </form-info-item>
            <template v-if="form.status !== 0">
              <form-info-item label="支付方式">{{paymentMethods[form.payType]}}</form-info-item>
              <form-info-item label="支付日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
            </template>
            <form-info-item label="支付账户" v-if="form.payerAccount">{{form.payerAccount}}</form-info-item>
            <form-info-item label="收款账户" v-if="form.payeeAccount">{{form.payeeAccount}}</form-info-item>
          </div>
          <div class="flex">
            <form-info-item label="记账单号">{{form.code}}</form-info-item>
            <form-info-item label="ERP单号" v-if="form.erpCode !== form.code">{{form.erpCode}}</form-info-item>
            <form-info-item label="创建人">{{form.createBy}}</form-info-item>
            <form-info-item label="创建时间">{{new Date(form.createAt).format()}}</form-info-item>
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <template v-if="form.status === 1 || form.status === 2">
              <form-info-item label="审批时间">{{new Date(form.checkTime).format()}}</form-info-item>
              <form-info-item label="审批意见">{{form.checkInfo}}</form-info-item>
            </template>
          </div>
        </div>
        <form-info-item label="备注">{{form.info}}</form-info-item>
      </el-form>
      <template v-if="payable && form">
        <el-divider content-position="left">支付费用</el-divider>
        <el-form ref="form" :model="form" label-width="74px" label-position="right" label-suffix=":">
          <el-form-item label="使用余额">
            <div class="h c">
              <price-input v-model="form.accountMoney" :min="0" :max="maxRemain / 100" style="width: 180px;" />
              <div class="padding-0-10">可使用余额：{{$price(maxRemain)}}</div>
            </div>
          </el-form-item>
          <template v-if="form.accountMoney < form.payMoney">
            <el-form-item label="支付方式">
              <el-radio-group v-model="form.payType">
                <el-radio-button :label="0">在线支付</el-radio-button>
                <el-radio-button :label="1">线下支付</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item key="outpay" prop="payerAccount" label="支付账户">
              <div class="h s">
                <el-input v-model="form.payerAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" v-if="form.friendEntId" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
            <el-form-item key="outrec" prop="payeeAccount" label="收款账户">
              <div class="h s">
                <el-input v-model="form.payeeAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.entId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="saving" @click="doPay">提交</el-button>
              <el-button @click="dialog = false">取消</el-button>
            </el-form-item>
          </template>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { add, edit, audit, pay } from "@/api/payForm";
import { getBySupplier } from "@/api/capitalPool";

export default {
  data() {
    return {
      dialog: false,
      form: null,
      saving: false,
      payable: false,
      maxRemain: 0,
      paymentMethods: ["在线支付", "线下支付"],
      paymentAccoutTypes: ["银联", "支付宝", "微信", "银行卡"],
      formTypes: ["银行卡", "支付宝", "微信"],
      status: [
        {
          type: "info",
          label: "待付款"
        },
        {
          type: "success",
          label: "已收款"
        },
        {
          type: "danger",
          label: "已拒收"
        },
        {
          type: "primary",
          label: "已支付"
        }
      ]
    };
  },
  methods: {
    handleClosed() {
      this.form = null;
      this.payable = false;
      this.maxRemain = 0;
    },
    getMaxRemain() {
      if (this.form && this.form.entId) {
        getBySupplier(this.form.entId).then(res => {
          let max = res.canUseBalance;
          if (max < 0) max = 0;
          if (max > this.form.payMoney) max = this.form.payMoney;
          this.maxRemain = max;
        });
      }
    },
    doPay() {
      let form = {
        id: this.form.id,
        accountMoney: this.form.accountMoney || 0,
        payType: this.form.payType,
        payerAccount: this.form.payerAccount,
        payeeAccount: this.form.payeeAccount
      };
      form.payMoney = (this.form.payMoney || 0) - form.accountMoney;
      if (form.payMoney < 0) form.payMoney = 0;
      if (form.payType === 0) {
        this.$alert("暂不支持在线支付功能。");
      } else {
        this.saving = true;
        pay(form)
          .then(res => {
            this.$notify({
              title: "支付成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.saving = false;
          });
      }
    },
    resetForm(form, payable) {
      this.form = form;
      if (payable && form.status === 0) {
        this.getMaxRemain();
        this.payable = true;
      }
      this.dialog = true;
    },
    handlePayerAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payerAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payerAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },
    handlePayeeAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payeeAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payeeAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    }
  }
};
</script>